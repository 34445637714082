import React, { useEffect, useState } from "react";

import { Box, Paper, Stack, Typography, Button, Grid2, CardMedia, TextField } from "@mui/material";
import { alpha, createTheme, ThemeProvider } from "@mui/material/styles";

import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";



// Components
import LandingHeader from "../components/Header";
import { FeedBack } from "../components/CustomComponents";
import { PasswordField } from "../components/LoginRegister";

// redux
import { forgotPasswordReset } from "../redux/slices/user-slice";
import { isEmail, checkPassword } from "../services/utilities";


// Images
const landing_bg = require("../assets/gavel_vid1.webp");

const contactFormBGBlack = alpha('#121212', .3);

const light = {
  palette: {
    mode: "light",
  },
};

export default function ResetPasswordPage() {
  return (
    <>
      <CardMedia src={`${landing_bg}`} component="img" autoPlay loop muted controls={false} sx={{
        pointerEvents: "none",
        position: "fixed",
        top: "50%",
        left: "50%",
        minHeight: "100%",
        minWidth: "100%",
        width: "auto",
        height: "auto",
        transform: "translateX(-50%) translateY(-50%);",
        zIndex: -1
      }}
      />
        <LandingHeader />
        <Box display="flex" alignItems="center" justifyContent="center" py={{ xs: 5, sm: 5, md: 20, lg: 25 }}  sx={{ bgcolor: contactFormBGBlack, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "100%", backdropFilter: "blur(3px)",  minHeight: "100%",  }}>
          <PasswordResetForm />
        </Box>
    </>
  );
}

const PasswordResetForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { code } = useParams();
  const [isDisabled, setIsDisabled] = useState(true);
  const [feedBack, setFeedback] = useState({passed: null, comp: null});
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  useEffect(() => {
    if(isEmail(email) && checkPassword(password)) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

  },[email, password])

  const resetClick = async () => {
    try {
      setFeedback({passed: null, comp: null})
      setIsDisabled(true);
      const payload = {
        email: email,
        new_password: password,
        unique_code: code
      }
      await dispatch(forgotPasswordReset(payload)).unwrap();
      setFeedback({passed: true, comp: <FeedBack passed={true} color="black" message={"Success! Please sign in"} mt={1} />});
      setTimeout(() => {
        navigate('/');
      }, 600);
    } catch (exception) { 
      setIsDisabled(false);
      const { detail } = exception;
      if (detail === "Not Acceptable") {
        setFeedback({passed: false, comp: <FeedBack passed={false} color="black" message={"Password didn't pass requirements"} mt={1} />});
      }
      if (detail === "Gone") {
        setFeedback({passed: false, comp: <FeedBack passed={false} color="black" message={"Your link has expired"} mt={1} />});
      }
    }
  }

  return(
    <ThemeProvider theme={createTheme(light)}>
    <Grid2 container>
      <Grid2 size={{xs: 12}}>
        <Paper sx={{ borderRadius: "20px",  }}>
          <Stack p={4} spacing={3}>
            <Box>
              <Typography
                variant="h4"
                noWrap
                sx={{
                  fontWeight: 700,
                  letterSpacing: ".1rem",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  flexGrow: 1,
                  fontSize: '1rem'
                }}>
                Reset your Password
              </Typography>
            </Box>
              <Box><TextField required fullWidth autoComplete="email" placeholder="e-mail" variant="standard" sx={{ mb: "1rem" }} onChange={(e) => setEmail(e.target.value)} /></Box>
              <Box>
                <PasswordField required variant="standard" placeholder="new-password" autoComplete="new-password" onChange={(e) => setPassword(e.target.value)} />
              </Box>
            <Button size="large" disabled={isDisabled} sx={{ position: "relative", borderRadius: "35px", padding: "8px 30px", alignSelf: 'center' }}variant="contained" onClick={resetClick}>Reset Password</Button>
            {feedBack.passed !== null && feedBack.comp}
          </Stack>
        </Paper>
      </Grid2>
    </Grid2>
    </ThemeProvider>
  );
}