import React, { useEffect, useState } from "react";

// Components
import { useMediaQuery, useTheme, AppBar, Box, Button, CardMedia, Grow, Link, Toolbar, Typography, Stack, Slide, IconButton, Container } from "@mui/material";
import { alpha } from '@mui/material/styles';

// Redux
import { useNavigate } from "react-router-dom";

// Icons
import LoginIcon from '@mui/icons-material/Login';


// Components
import LoginRegister from "./LoginRegister";

// const fmg_header_logo = require("../assets/fmg_header_logo.png");
const fmg_header_logo = require("../assets/fmg_logo.png");

// Services

// Plugins

// Constants
const alphaBlack = alpha('#121212', 0);
const alphaBlack2 = alpha('#121212', .55);
const alphaWhite = alpha('#ffffff', .5);
const alphaMidnightBlue = alpha('#191970', .65);

export default function GeneralLandingHeader() {
  const theme = useTheme();
  const navigate = useNavigate();
  const lgBP = useMediaQuery(theme.breakpoints.up("lg"));
  const mdBP = useMediaQuery(theme.breakpoints.up("md"));


  const [loginState, setLoginState] = useState(false);

  // const handleLoginOpen = function () {
  //   setLoginState(true);
  // };
  const handleLoginOpen = () => setLoginState(true);
  const handleLoginClose = () => setLoginState(false);


  return (
    <>
      {loginState && <LoginRegister handleClose={handleLoginClose} isOpen={loginState} />}
      <AppBar
        component="header"
        position="static"
        sx={{
          bgcolor: alphaMidnightBlue,
          py: 4
        }}
      >
        {/* <Toolbar disableGutters sx={{ display: "flex", flexDirection: "row", justifyContent: mdBP ? "space-evenly" : "space-around", }}> */}
        <Container maxWidth='xl'>
          <Toolbar disableGutters sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", }}>
            {/* <Stack direction="row" spacing={10}> */}
              <Box sx={{ maxHeight: "300px", maxWidth: mdBP ? "350px" : "200px", width: "auto", height: "auto", }}>
                <CardMedia component="img"  image={fmg_header_logo} alt="logo"  />
              </Box>
              {lgBP ? <Typography fontSize={"2.5rem"} color="white" sx={{ textShadow: '0 black', fontStyle: "italic", alignSelf: "flex-end",  }}>Soar Above The Competition</Typography> : null}
            {/* </Stack> */}
            <Box>
              {/* {mdBP ?
                <Button size="large" variant="outlined"
                  onClick={() => handleLoginOpen()}
                  sx={{
                    width: "12rem",
                    height: "3.5rem",
                    borderRadius: '3rem',
                    bgcolor: "#834bff",
                    boxShadow: "0 0 50px 5px #f0f",
                    border: "2px solid yellow",
                    "&:hover": {
                      bgcolor: "#E67451",
                      boxShadow: "0 0 40px 5px yellow",
                    }
                  }}>
                <Typography fontSize={"1.25rem"} fontWeight="600" color="white" sx={{ textShadow: '0px 1px 0 black' }}>Sign In</Typography>
              </Button> : 
              <Box display="flex" flexDirection="column" alignItems="center">
                <IconButton aria-label="login" onClick={() => handleLoginOpen()} sx={{ boxShadow: "0 0 30px 2px white", maxWidth: "60px", mb: 2 }}>
                  <LoginIcon fontSize="large" sx={{ color: "white" }} />
                </IconButton>
                <Typography fontSize={"1rem"} fontWeight="600">Login</Typography>
              </Box>
              } */}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
